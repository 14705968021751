import '../styles/accordion.scss';

import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import DownArrow from '../images/DownArrow.jpeg';
import illustration_bubble_tip from '../images/illustration-bubble-tip@3x.png';
import righttick from '../images/images/icon-tick-green.svg';
import manWithBall from '../images/manWithBall.png';
import sbliImg from '../images/sbli.png';
import UpArrow from '../images/UpArrow.jpeg';
import * as headerStyles from '../styles/common.module.css';

let getUniqueSectionData = ( data ) => {
  let uniqueSectionArray = [];
  let len = data.length;
  for ( let i = 0; i < len; i++ ) {
    if ( !uniqueSectionArray.includes( data[i].node.data_category ) ) {
      uniqueSectionArray.push( data[i].node.data_category );
    }

  }
  return uniqueSectionArray;
};

const SBLI = () => {
  const data = useStaticQuery( graphql`
      query {
        allStrapiPageData(filter: {page_name: {eq: "faq_page_3"}}) {
          edges {
            node {
              id
              page_name
              style_category
              content
              attribute_1_key
              attribute_1_value
              attribute_2_key
              attribute_2_value
              column
              data_category
              version
            }
        }
      }
      }
    ` );

  const [ showResults, setShowResults ] = React.useState( {} );
  const [ showSections, setShowSections ] = React.useState( {} );
  let pageData = data.allStrapiPageData.edges;
  let uniqueSectionData = getUniqueSectionData( pageData );
  let visited = [];
  let sectionsLength = uniqueSectionData.length;

  for ( let i = 0; i < sectionsLength; i++ ) {
    visited[uniqueSectionData[i]] = false;
  }

  const onClick = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> ' + event.target.name );
    if ( !showResults[index] ) {
      setShowResults( { ...showResults, [index]: true } );
    }
    else {
      setShowResults( { ...showResults, [index]: false } );
    }
  };
  const onClick_section = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> ' + event.target.name );
    if ( !showSections[index] ) {
      setShowSections( { ...showSections, [index]: true } );
    }
    else {
      setShowSections( { ...showSections, [index]: false } );
    }
  };
  return (
    <Layout>
      {/* Menu section */}

      <SEO keywords="sbli life insurance payment, sbli term life insurance, SBI Life Online, sbli term life insurance reviews, sbli term life insurance quote"
        canonicalUrl='https://www.getmybubble.com/SBLI/'
        title="SBLI- Buy Term Life Insurance Plan Online in Simple &amp; Affordable Way | Get My Bubble"
        description='With SBLI term life insurance, protect your loved ones. Buy SBLI life insurance plan online easy way. Simple Process | Addordable Premium | Get Insurance in a Single Click' />

      <div className="flexColumn carrierCommonPage">
        <div className="container-fluid carrier-plan-container" >

          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="padding-left">
                  <img src={sbliImg} className='sbli-top-logo' alt="SBLI icon"/>

                  <h1 className="sbli-heading">
                      Life insurance that treats you better.
                  </h1>

                  <p className="sbli-section1-para">
                      The whole reason we do things differently around here is to make the process of buying life insurance a lot less complicated, confusing and unpleasant. In fact, we always strive to deliver Total Customer Happiness. With SBLI, you may just be surprised by how great you feel when getting life insurance to protect the ones you love most.
                  </p>

                  <div className="flexRow align-btns ml-1">
                    <button type="button" className="orangeBtn mr-3 sbli-orange-button">
                        Get a quote now
                    </button>

                    <a href="#start-exploring" style={{ color: '#ff7007', textDecoration: 'none' }}>
                      <button type="button" className="explore">
                          Explore
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 sbli-image" style={{ textAlign: 'center' }}>
                <img src={manWithBall} alt="bgimg" className=" carrier-right-img-mv mt-4 mt-sm-4 mt-md-4 mt-lg-0"/>
              </div>
            </div>
          </div>

        </div>

        {/* how sbli works section */}

        <div className="container-fluid" style={{ marginTop: '80px' }}>
          <div className="row">
            <div className="col-lg-3"></div>

            <div className="col-lg-6">
              <h3
                className="sbli-title1"
              >
                How SBLI Term works
              </h3>

              <p className="sbli-works-para">
                Life insurance doesn’t have to be complicated. SBLI has made it easy for you to get simple, affordable term life insurance to protect and provide for your family. Get a decision on the spot with an easy online application that you can start and finish in minutes.
              </p>

            </div>

            <div className="col-lg-3"></div>
          </div>
        </div>

        {/* product highlights section */}

        <div className="container sbli-product-highlights">
          <div className="row" style={{ marginTop: '9px' }}>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-2"></div>

                <div className="col-lg-9 product-container-alignment">
                  <div className="sbli-product-container">

                    <div className="align-horizontal-center">
                      <img src={illustration_bubble_tip} style={{ height: '100px', width: '77px' }} alt="bgimg"/>

                      <h3
                        className="ph-title">
                        Product Highlights
                      </h3>
                    </div>

                    <div className='product-row'>
                      <img src={righttick} className="img-responsive" style={{ verticalAlign: 'middle' }} alt="bgimg"/>

                      <div className="ph-item-style pl-3">

                        Guaranteed premiums that stay the same for the selected initial term period.
                        Plus, at the end of this level period, the policy can be continued to age 85 at annually increasing rates
                      </div>
                    </div>

                    <div className='product-row'>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Coverage amounts from $25,000 to $1,000,000, depending on age and income
                      </div>

                    </div>

                    <div className='product-row'>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Currently available for citizens and legal residents in 49 states, excluding NY
                      </div></div>

                    <div className='product-row'>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Issue ages 18-55 years old
                      </div></div>

                    <div className='product-row'>
                      <img src={righttick} className="tick" alt=""/>

                      <div className="ph-item-style pl-3">
                        Single Insured coverage only
                      </div></div>

                    <div className='product-row'>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        20-day free look-back period
                      </div></div>


                    <p className='sbli-product-terms'> 10-Year term is available for ages 18-55, while 15- and 20-Year term is available for ages 18-50</p>
                  </div>

                </div>

                <div className="col-lg-1"></div>
              </div>
            </div>

            <div className="col-lg-6 carrier-middle-container carrier-middle-container-mv sbli-product-section2">
              <h4 className="stillwater-col2-title">UnderWriting</h4>

              <ul className="sbli-list sbli-list-item" style={{ marginLeft: '12px' }}>
                <li>
                  Choice of 10, 15, or 20-Year Level Term Life Insurance Age 18-551
                </li>

                <li>
                  Simplified Issue process with an on the spot decision
                </li>

                <li>
                  No medical exam required
                </li>

                <li>
                  Health classes: Preferred Plus, Preferred, Select, Standard,Nicotine
                </li>
              </ul>

              <h4 className="stillwater-col2-title">Qualifying Life Events</h4>

              <ul className="sbli-list sbli-list-item" style={{ marginLeft: '12px' }}>
                <li>
                  Marriage, a domestic partnership, or a civil union Adding a member to the family- either a newborn baby,
                  adopted child or stepchild
                </li>

                <li>
                  Investing in a home or taking out a personal loan
                </li>

                <li>
                  A salary increase of more than 10% over the prior year
                </li>
              </ul>

              <h4 className="stillwater-col2-title">Accelerated Death Benefit Rider</h4>

              <p className="stillwater-col2-para" style={{ marginTop: '8px' }}>
                Included at no additional cost, providing early access to a portion of the death benefit if the insured is diagnosed with a terminal illness
              </p>
            </div>
          </div>
        </div>

        {/* Strength and ratings section */}

        <div className="container-fluid ratings" >
          <div className="row">
            <div className="col-lg-3"></div>

            <div className="col-lg-6" style={{ textAlign: 'center' }}>
              <h3 className="stillwater-title1">Strength and Ratings</h3>

              <img src={sbliImg} className='sbliIcon' alt="SBLI icon"/>

              <p className="sbli-para2">
                Our financial foundation has always been strong. And we take a prudent,
                cautious approach to our finances to ensure it always will be. For more than 110 years,
                policyholders have been able to trust that our financial strength means we’ll be there for
                them when they need us most.
              </p>

            </div>

            <div className="col-lg-3"></div>
          </div>
        </div>

        {/* SBLI Cards */}

        <div className="container sbli-card-container">
          <div className="row">
            <div className="col-lg-1">
            </div>

            <div className="col-lg-10">

              <div className="card-deck align-stillwater-cards" style={{ justifyContent: 'center' }}>

                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4 mx-auto section30">
                    <div className="card sbli-cards" >
                      <div className="card-header align-sbli-card-header">
                    1M+
                        <div style={{ fontSize: '24px', color: '#ff7007' }}> FAMILIES PROTECTED</div>
                      </div>

                      <div className="card-body sbli-card-bg" style={{ backgroundColor: '#ff7007' }}>
                        <div className="sbli-card-text">Since founding, we have protected over 1,000,000 families.</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 mx-auto section30">
                    <div className="card sbli-cards" >
                      <div className="card-header align-sbli-card-header">
                    4B+
                        <div style={{ fontSize: '24px', color: '#ffa61a', padding: '0 20px' }}>BENEFITS PAID</div>
                      </div>

                      <div className="card-body sbli-card-bg" style={{ backgroundColor: '#ffa61a' }} >
                        <p className="sbli-card-text">Continuing to meet the needs of policyholders when they needed us the most.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 mx-auto section30">
                    <div className="card sbli-cards">
                      <div className="card-header align-sbli-card-header">
                    GRADE A
                        <div style={{ fontSize: '24px', color: '#00a12f' }}>A.M BEST (EXCELLENT)</div>
                      </div>

                      <div className="card-body sbli-card-bg" style={{ backgroundColor: '#00a12f' }} >
                        <p className="sbli-card-text">Continuing to meet the needs of policyholders when they needed us the most.</p>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

            <div className="col-lg-1">
            </div>
          </div>
        </div>

        {/* FAQ section */}

        <div className="container-fluid sbli-faq-container" style={{ backgroundColor: '#f5fafc' }}>
          <div className=" text-center">
            <h2 className="sbli-faq-box-head">Frequently Asked Questions</h2>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {data.allStrapiPageData.edges.map( ( s, section_index ) => {
                  if ( visited[s.node.data_category] == false ) {
                    visited[s.node.data_category] = true;
                    return (
                      <div className="row" key={section_index}>
                        <div className="col-lg-12">
                          <div className="carrier-faq-heading2">
                            <div className={headerStyles.faqLeftContainer + ' faq-left-container'}>
                              <div className="col-12 align-sbli-faq-que padding-for-mv" onClick={function ( e ) { onClick_section( e, section_index ); }} style={{ cursor: 'pointer' }}>
                                <div className="sbli-faq-section-heading padding-for-mv">{s.node.attribute_1_value}</div>
                              </div>

                              <div className="row">
                                {data.allStrapiPageData.edges.map( ( d, index ) => {
                                  if ( showSections[section_index] && d.node.data_category == s.node.data_category ) {
                                    return (
                                      <div key={index} className="col-12 col-lg-6">
                                        <div className={headerStyles.faqSubContainer + ' col-sm-12'} >
                                          <div className={headerStyles.faqFlex + ' faq-container-align'} onClick={function ( e ) { onClick( e, index ); }} style={{ backgroundColor: 'white', cursor: 'pointer' }}>

                                            <div className={headerStyles.faqLeftContainer}>
                                              <div className="col-12 padding-faq-mv">
                                                <div className="align-faq-que">
                                                  <div className="carrier-faq que-padding"> {d.node.attribute_2_key} </div>
                                                </div>

                                                {showResults[index] ? <p className="pt-lg-0 pt-2 carrier-faq">{d.node.content}</p> : null}
                                              </div>
                                            </div>

                                            <div className="arrow3">
                                              <div className={headerStyles.faqRightContainer + ' align-arrow' + showResults[index] ? '' : ' align-arrow-img'}>
                                                <a className={headerStyles.custPointer}><img src={showResults[index] ? UpArrow : DownArrow} className="faqMainArrow" name={index} alt="Bubble Life Insurance"/></a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                } )}
                              </div>
                            </div>

                            <div className={headerStyles.faqRightContainer + ' align-faq-arrow' + ' faq-right-container'} style={{ width: '5%' }}>
                              <a className={headerStyles.custPointer}><img src={showSections[section_index] ? UpArrow : DownArrow} className="faqMainArrow" name={section_index} onClick={function ( e ) { onClick_section( e, section_index ); }} style={{ cursor: 'pointer' }} alt="Bubble Life Insurance"/></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                } )}
              </div>
            </div>
          </div>
        </div>

        {/* plansForYou section */}

        <div className="plansForYou">
          <div className="orangeBackGround">
            <div>
              Find out which plan is right for you
            </div>

            <div className="customizedPlan">
              Policies are customized according to your needs.
              We have simplified the process to help you find the right policy that fits your budget and loved ones.
            </div>

            <Link to="/" type="button" className="btnWhite">
              Get your quote now
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default SBLI;
